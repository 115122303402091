import React from 'react';
import {Container} from 'bloomer';
import {ContTitle, GlobalColors} from '../../../styles/GlobalStyles';
import {ContSectionPlano} from './stylesPlano';
import TabsPlano from './TabsPlano';

export const PlanoPuntaEscondidaComp = () => {

  return (
    <ContSectionPlano>
      <Container>
        <ContTitle color={GlobalColors.colorLight}>Plano</ContTitle>

        <TabsPlano/>
      </Container>

    </ContSectionPlano>
  )
}
