import React from "react"
import {
  MyTabs,
  MyTabList,
  MyTab,
  MyTabPanels,
  MyTabPanel,
  GlobalStyleTabsPlano,
} from './stylesTabsPlano';
import ItemTabsPlano1y3 from "./itemTabsPlano1y3";
import ItemTabsPlano2y4 from './itemTabsPlano2y4';

const TabsPlano = () => {
  const fnTabClick = (val) => {
    const myClass = 'tab-active';
    const elements = document.querySelectorAll('.tabs-plano');

    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.remove(myClass);
    }

    document.getElementById(`tab-${val}`).classList.add('tab-active');
  }

  React.useEffect(() => {
    const elements = document.querySelectorAll('.tabs-plano');

    for (let i = 0; i < elements.length; i++) {
      elements[i].addEventListener("click", function() {
        fnTabClick(i + 1);
      });
    }

    // cleanup this component
    return () => {
      for (let i = 0; i < elements.length; i++) {
        elements[i].removeEventListener("click", fnTabClick);
      }
    };
  }, []);

  return (
    <MyTabs>
      <GlobalStyleTabsPlano/>
      <MyTabList>
        <MyTab id={'tab-1'} className={'tabs-plano tab-active'}>Manzana 1 y 3</MyTab>
        <MyTab id={'tab-2'} className={'tabs-plano'}>Manzana 2 y 4</MyTab>
      </MyTabList>

      <MyTabPanels>
        <MyTabPanel>
          <ItemTabsPlano1y3/>
        </MyTabPanel>
        <MyTabPanel>
          <ItemTabsPlano2y4/>
        </MyTabPanel>
      </MyTabPanels>
    </MyTabs>
  )
}
export default TabsPlano
