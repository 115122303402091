import React from 'react';
import {ContBackSecPrin, ContBlurLema, ContButton, ContLema, Lema, Lugar, Title} from './stylesPuntaEscondidaBack';
import ButtonGuzbarraf from '../../UI/Button';

export const PuntaEscondidaBackComp = ({title, lugar, img}) => {

  return (
    <ContBackSecPrin id={'inicio'} img={img}>
      <ContLema>
        <ContBlurLema>&nbsp;</ContBlurLema>
        <Lema>
          <Title>{title}</Title>
          <Lugar>{lugar}</Lugar>
          <ContButton>
            <ButtonGuzbarraf
              text={'Contactar'}
              className={'btnPrimary'}
              onClick={() => alert('123')}
            />
          </ContButton>
        </Lema>
      </ContLema>
    </ContBackSecPrin>
  )
}
