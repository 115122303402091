import * as React from "react"
import LayoutPages from '../components/Layout/layoutPages';
import Seo from "../components/seo"
import { useSiteMetadata } from "../hooks/use-site-metadata"
import PuntaEscondidaComp from '../components/PuntaEscondida';

const PuntaEscondidaPage = (props) => {
  const { title, siteUrl } = useSiteMetadata();

  return (
    <LayoutPages pathname={props.location.pathname}>
      <Seo
        title={title}
        description="Seguridad y confianza en tu patrimonio"
        keywords='patrimonio, terrenos, bienes raices, inmobiliaria'
        lang={`es`}
        imageF='inmoax-punta-escondida-f.jpg'
        imageT='inmoax-punta-escondida-t.jpg'
        url={`${siteUrl}/`}
      />

      <PuntaEscondidaComp {...props} />

    </LayoutPages>
  )
}

export default PuntaEscondidaPage
