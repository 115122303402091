import React from 'react';
import {Container, Columns, Column} from 'bloomer';
import {ContTitle} from '../../../styles/GlobalStyles';
import {
  ContDesarrollosDetalles,
  ContDetalles,
  ContItemDetalle, ContUlDetalles,
  ContVerticalLine,
  ItemDetalle,
  TextoDetalle,
  TitleDetalle, TitleFacilidades,
  TitleManzanas,
  UlDetalles,
} from './stylesDesarrollosDetallesPuntaEscondida';

export const DesarrollosDetallesPuntaEscondidaComp = () => {

  return (
    <ContDesarrollosDetalles>
      <Container>
        <ContTitle>Detalles</ContTitle>

        <Columns className="columns-responsive full-height">
          <Column isSize={{mobile: 0, tablet: 0, desktop: 3, widescreen: 3}} className={'no-display-mobile'}>
            &nbsp;
          </Column>
          <Column isSize={{mobile: 12, tablet: 12, desktop: 6, widescreen: 6}}>
            <ContUlDetalles>
              <UlDetalles>
                <li>
                  Se ubica a 10 min. de Puerto Escondido en los Alejos Bajos de Chila, San Pedro Mixtepec, Juquila,
                  Oaxaca.
                </li>
                <li>Es terreno comunal, lo que se tramita es un acta de posesión.</li>
                <li>Cuenta con 71 lotes.</li>
                <li>Cada lote contará con el servicio de agua potable, a través de una red de tubería interna,
                  alimentada por un pozo semiprofundo.
                </li>
                <li>800 mts. de playa con acceso directo.</li>
                <li>Se ubica a 8 minutos de la laguna de Manialtepec.</li>
              </UlDetalles>
            </ContUlDetalles>
          </Column>
          <Column isSize={{mobile: 0, tablet: 0, desktop: 3, widescreen: 3}} className={'no-display-mobile'}>
            &nbsp;
          </Column>
        </Columns>

        <Columns className="columns-responsive full-height">
          <Column isSize={{mobile: 0, tablet: 0, desktop: 3, widescreen: 3}} className={'no-display-mobile'}>
            &nbsp;
          </Column>
          <Column isSize={{mobile: 12, tablet: 12, desktop: 3, widescreen: 3}}>
            <ContDetalles>

              <TitleManzanas>
                <div>Manzanas</div>
                <div>1 y 2</div>
              </TitleManzanas>

              <ContItemDetalle>
                <ItemDetalle>
                  <TitleDetalle>Medidas</TitleDetalle>
                  <TextoDetalle>9x22</TextoDetalle>
                </ItemDetalle>

                <ItemDetalle>
                  <ContVerticalLine/>
                </ItemDetalle>

                <ItemDetalle>
                  <TitleDetalle>Total</TitleDetalle>
                  <TextoDetalle>198 m<sup>2</sup>.</TextoDetalle>
                </ItemDetalle>
              </ContItemDetalle>

              <ContItemDetalle>
                <ItemDetalle>
                  <TitleDetalle>Contado</TitleDetalle>
                  <TextoDetalle>$180,000 <span>MXN</span></TextoDetalle>
                </ItemDetalle>
              </ContItemDetalle>

              <TitleFacilidades>Facilidades</TitleFacilidades>

              <ContItemDetalle>
                <ItemDetalle>
                  <TitleDetalle>12 meses</TitleDetalle>
                  <TextoDetalle>$16,667 <span>MXN</span></TextoDetalle>
                </ItemDetalle>
              </ContItemDetalle>

              <ContItemDetalle>
                <ItemDetalle>
                  <TitleDetalle>24 meses</TitleDetalle>
                  <TextoDetalle>$9,167 <span>MXN</span></TextoDetalle>
                </ItemDetalle>
              </ContItemDetalle>

              <ContItemDetalle>
                <ItemDetalle>
                  <TitleDetalle>36 meses</TitleDetalle>
                  <TextoDetalle>$6,667 <span>MXN</span></TextoDetalle>
                </ItemDetalle>
              </ContItemDetalle>
            </ContDetalles>
          </Column>
          <Column isSize={{mobile: 12, tablet: 12, desktop: 3, widescreen: 3}}>
            <ContDetalles>
              <TitleManzanas>
                <div>Manzanas</div>
                <div>3 y 4</div>
              </TitleManzanas>

              <ContItemDetalle>
                <ItemDetalle>
                  <TitleDetalle>Medidas</TitleDetalle>
                  <TextoDetalle>9x21</TextoDetalle>
                </ItemDetalle>

                <ItemDetalle>
                  <ContVerticalLine/>
                </ItemDetalle>

                <ItemDetalle>
                  <TitleDetalle>Total</TitleDetalle>
                  <TextoDetalle>189 m<sup>2</sup>.</TextoDetalle>
                </ItemDetalle>
              </ContItemDetalle>

              <ContItemDetalle>
                <ItemDetalle>
                  <TitleDetalle>Contado</TitleDetalle>
                  <TextoDetalle>$200,000 <span>MXN</span></TextoDetalle>
                </ItemDetalle>
              </ContItemDetalle>

              <TitleFacilidades>Facilidades</TitleFacilidades>

              <ContItemDetalle>
                <ItemDetalle>
                  <TitleDetalle>12 meses</TitleDetalle>
                  <TextoDetalle>$18,334 <span>MXN</span></TextoDetalle>
                </ItemDetalle>
              </ContItemDetalle>

              <ContItemDetalle>
                <ItemDetalle>
                  <TitleDetalle>24 meses</TitleDetalle>
                  <TextoDetalle>$10,000 <span>MXN</span></TextoDetalle>
                </ItemDetalle>
              </ContItemDetalle>

              <ContItemDetalle>
                <ItemDetalle>
                  <TitleDetalle>36 meses</TitleDetalle>
                  <TextoDetalle>$7,223 <span>MXN</span></TextoDetalle>
                </ItemDetalle>
              </ContItemDetalle>
            </ContDetalles>
          </Column>
          <Column isSize={{mobile: 0, tablet: 0, desktop: 3, widescreen: 3}} className={'no-display-mobile'}>
            &nbsp;
          </Column>
        </Columns>

      </Container>

    </ContDesarrollosDetalles>
  );
};
