import React from 'react';
import {GlobalStyle} from '../Home/styles';
import {PuntaEscondidaBackComp} from '../DesarrollosBack/PuntaEscondida/PuntaEscondidaBack';
import ImgBack from '../../images/desarrollos/punta-escondida/punta-escondida-g.jpg';
import {DesarrollosDetallesPuntaEscondidaComp} from '../DesarrollosDetalles/PuntaEscondida/DesarrollosDetallesPuntaEscondida';
import {PlanoPuntaEscondidaComp} from '../Planos/PuntaEscondida/Plano';
import {UbicacionComp} from '../Ubicacion/';

const title = 'Punta Escondida';
const lugar = 'Se ubica a 10 min. de Puerto Escondido.';

const PuntaEscondidaComp = () => {

  return (
    <div className="cont-page page-home">
      <GlobalStyle/>
      <PuntaEscondidaBackComp title={title} lugar={lugar} img={ImgBack}/>
      <DesarrollosDetallesPuntaEscondidaComp/>
      <PlanoPuntaEscondidaComp/>
      <UbicacionComp desarrollo={'puntaescondida'}/>
    </div>
  )
}

export default PuntaEscondidaComp
